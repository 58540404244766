<template>
    <!--新增对话框-->
    <el-dialog
            title="申请打款"
            :visible.sync="dialogVisible"
            width="500"
            :close-on-click-modal="false"
            :before-close="handleClose">

        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">
            <el-form-item label="银行名称" prop="bankName" label-width="150px">
                <el-input v-model="editForm.bankName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="银行卡卡号" prop="cardNumber" label-width="150px">
                <el-input v-model="editForm.cardNumber" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="开户姓名" prop="customerName" label-width="150px">
                <el-input v-model="editForm.customerName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="开户身份证号" prop="idNubmer" label-width="150px">
                <el-input v-model="editForm.idNubmer" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="提现金额" prop="deaMoney" label-width="150px">
                <el-input v-model="editForm.deaMoney" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
                <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        name: "Sqdk",
        data(){
            return{
                editForm:{},
                dialogVisible:false,
                editFormRules: {
                    bankName: [
                        {required: true, message: '请输入银行名称', trigger: 'blur'}
                    ],
                    cardNumber: [
                        {required: true, message: '请输入银行卡卡号', trigger: 'blur'}
                    ],
                    idNubmer:[
                        {required: true, message: '请输入开户身份证号', trigger: 'blur'}
                    ],
                    deaMoney: [
                        {required: true, message: '请输入提现金额', trigger: 'blur'}
                    ],
                    customerName:[
                        {required: true, message: '请输入开户姓名', trigger: 'blur'}
                    ]
                },
            }
        },
        methods:{
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
            },
            handleClose() {
                this.resetForm('editForm')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/admin/commissionapplication/sqdk', this.editForm).then(res => {
                            this.$message({
                                showClose: true,
                                message: '恭喜你，操作成功',
                                type: 'success',
                                onClose:() => {
                                    this.$emit('refreshDataList')
                                }
                            });
                            this.dialogVisible = false
                            this.resetForm(formName)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            init(){
                this.dialogVisible=true;
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
